import { default as _91_46_46_46slug_93gGbPnl9WvbMeta } from "/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue").then(m => m.default || m)
  }
]