import revive_payload_client_4sVQNw7RlN from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/dennisdarko/Sites/zweikern-website_v2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import anchorscroll_plugin_H0zwu1Xy9f from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import plugin_YeYU6tgo5H from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@davestewart/nuxt-scrollbar/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_8SbxDRbG6Y from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_y9dIov5OEk from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt3-vuex-module/src/plugin.js";
import plugin_KlVwwuJRCL from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/Users/dennisdarko/Sites/zweikern-website_v2/plugins/Vue3Lottie.client.ts";
import external_script_Or9pxAMqsn from "/Users/dennisdarko/Sites/zweikern-website_v2/plugins/external-script.js";
import reveal_animation_SIJmpUt8Aw from "/Users/dennisdarko/Sites/zweikern-website_v2/plugins/reveal.animation.js";
import vue_apex_charts_client_QB9py3ZjdH from "/Users/dennisdarko/Sites/zweikern-website_v2/plugins/vue-apex-charts.client.ts";
import vuetify_7h9QAQEssH from "/Users/dennisdarko/Sites/zweikern-website_v2/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  anchorscroll_plugin_H0zwu1Xy9f,
  plugin_YeYU6tgo5H,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_8SbxDRbG6Y,
  plugin_y9dIov5OEk,
  plugin_KlVwwuJRCL,
  plugin_AUP22rrBAc,
  Vue3Lottie_client_bMZiOS6AB0,
  external_script_Or9pxAMqsn,
  reveal_animation_SIJmpUt8Aw,
  vue_apex_charts_client_QB9py3ZjdH,
  vuetify_7h9QAQEssH
]