<script setup lang="ts">
import StoryblokClient from "storyblok-js-client";

const runtimeConfig = useState("runtimeConfig");
const websiteConfig = useState("config");
const storyblokStore = useStoryblokStore();
const { $store } = useNuxtApp();

// storyblokStore.reset();

if (!storyblokStore.runtimeConfig) {
  runtimeConfig.value = useRuntimeConfig();
  storyblokStore.runtimeConfig = runtimeConfig.value;
} else {
  runtimeConfig.value = storyblokStore.runtimeConfig;
}

const config = runtimeConfig.value;

const storyblokApiOptions = useStoryblokApiOptions();
const storyblokApi = useStoryblokApi();

if (!storyblokStore.links) {
  await useStoryblokI18nLinks();
  // console.log("setLinks", $store.getters.getLinks);
}

$store.dispatch("setLinks", storyblokStore.links);

if (!storyblokStore.configLoaded) {
  try {
    const Storyblok = new StoryblokClient({
      accessToken: config.public.storyblok.accessToken,

      cache: {
        clear: "auto",
        type: "memory",
      },
      maxRetries: 20,
    });

    await Storyblok.get("cdn/stories/config", {
      // resolve_links: "url",
      version: "draft",
    })
      .then((response) => {
        storyblokStore.config = response;
        storyblokStore.configLoaded = 1;
      })
      .catch((error) => {
        console.error("app.vue", error);
      });
    //
    // storyblokStore.config = await storyblokApi.get("cdn/stories/config", {
    //   ...storyblokApiOptions,
    // });
  } catch (error) {
    console.error("error", error);
  }

  // console.log("load config", storyblokStore.configLoaded);
}

websiteConfig.value = storyblokStore.config;
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
